import React from 'react'

import plugIcon from '../images/plug.svg'
import ScrollAnimation from 'react-animate-on-scroll'

import './header.css'

class AnimatedHr extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: '150px', margin: 'auto' }}>
        <ScrollAnimation animateIn="scale-in-hor-left">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <hr
              style={{
                width: '70px',
                height: '5px',
                backgroundColor: '#f98b38',
                border: 'none',
                margin: '25px 10px 25px 0',
              }}
            />
            <hr
              style={{
                width: '20px',
                height: '5px',
                backgroundColor: '#f98b38',
                border: 'none',
                margin: '25px 10px 25px 0',
              }}
            />
            <hr
              style={{
                width: '10px',
                height: '5px',
                backgroundColor: '#f98b38',
                border: 'none',
                margin: '25px 0 25px 0',
              }}
            />
            <span
              style={{
                height: '21px',
                width: '24px',
                backgroundImage: 'url(' + plugIcon + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center left',
              }}
            />
          </div>
        </ScrollAnimation>
      </div>
    )
  }
}

export default AnimatedHr
